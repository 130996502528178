import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {MenuItem, TreeNode} from "primeng/api";
import {Observable, Subscription} from "rxjs";
import {BomItemNode} from "../tree/bom-item-node";
import {BomItemFilter, BomItemNodeQuery, StageItem} from "../bom-group.model";
import {DevEnvService} from "@cafe/dev-env";
import {TreeComponent} from "@infogix/angular-ui-framework";
import {PipelineService} from "./pipeline.service";
import {BomGroupMediator, TreeRefreshRequest} from "../bom-group-mediator";
import {AppService, ExtJsService} from "@cafe/web-ui-framework";
import {BomActionsHelper} from "../util/action/bom-actions-helper";

interface PipelineTreeStates {
    expandedNodes:TreeNode[]
}

@Component({
    selector: "dqp-pipeline-tree",
    templateUrl: "./pipeline-tree.component.html",
    styleUrls: ["./pipeline-tree.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineTreeComponent implements OnInit, OnDestroy {
    private static BOM_TREE_STATES_KEY:string = "BOM_TREE_STATES_KEY";

    @Output() public selectionChange: EventEmitter<any> = new EventEmitter();
    /**
     * Current tree selection.
     */
    public selection: any;

    public contextMenuItems: MenuItem[] = [];

    /** Nodes directly under the root node to display. */
    private _treeNodes: TreeNode[];

    /** Flag to show/hide the tree's loading indicator. */
    public loading: boolean;

    public nodeTooltip:string;

    private _bomFilter:BomItemFilter = new BomItemFilter("", []);

    private subscriptions: Subscription = new Subscription();

    @ViewChild(TreeComponent)
    private tree;

    constructor(private pipelineService: PipelineService, private devEnvService: DevEnvService,
                private mediator:BomGroupMediator,
                private bomActionsHelper:BomActionsHelper,
                private extJsService:ExtJsService,
                private appService:AppService,
                private cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.subscriptions.add(this.mediator.refreshTree$.subscribe((request:TreeRefreshRequest)=>{
            this.handleRefreshTreeRequest(request);
        }));
        this.subscriptions.add(this.mediator.allPipelinesSelection$.subscribe(({isSelect})=>{
            this.handleAllPipelineSelection(isSelect);
        }));
        this.subscriptions.add(this.extJsService.onExtJsEvent("ngRefreshPipelineTree").subscribe(next=>{
           this.refreshTree$().subscribe();
        }));
    }

    public get treeNodes(): TreeNode[] {
        return this._treeNodes;
    }

    public set treeNodes(value: TreeNode[]) {
        this._treeNodes = value;
        this.mediator.treeNodes = value;
    }

    public isEmpty():boolean {
        return (this.treeNodes == null || this.treeNodes.length == 0);
    }

    @Input()
    public get bomFilter() {
        return this._bomFilter;
    }

    public set bomFilter(newValue:BomItemFilter) {
        this.setBomFilter(newValue);
    }

    public setBomFilter(newValue:BomItemFilter, forceRefresh?:boolean) {
        if (newValue != null) {
            let filterChanged = true;
            if (this._bomFilter) {
                filterChanged = !newValue.equals(this._bomFilter);
            }
            // console.log("filterChanged = ", filterChanged);
            if (filterChanged || forceRefresh === true) {
                this.refreshTree$(this.createBomItemNodeQuery("root", newValue)).subscribe();
            }
        }
        this._bomFilter = newValue;
    }

    public onNodeLabelMouseEnter(node:TreeNode, nodeLabelElemRef:ElementRef) {
        this.nodeTooltip = this.getTooltipText(node, nodeLabelElemRef);
        this.cdr.markForCheck();
    }

    public onNodeLabelMouseLeave() {
        this.nodeTooltip = null;
        this.cdr.markForCheck();
    }

    public getTooltipText(node:TreeNode, nodeLabelElemRef:ElementRef):string {
        let tooltip = null;
        if (node.data) {
            let isOverflowing = false;
            if (nodeLabelElemRef) {
                const firstSpanElem = nodeLabelElemRef.nativeElement.firstElementChild;
                if (firstSpanElem && (firstSpanElem.clientWidth < firstSpanElem.scrollWidth)) {
                    isOverflowing = true;
                }
            }
            const description = node.data.bomItem.description;
            const dirty:boolean = node.data.bomItem.dirty;
            let label = node.label;
            if (dirty) {
                label += ` <span class="dqp-dirty-indicator fa fa-asterisk"></span>`;
            }
            if (description) {
                // has description
                tooltip=`<span style="font-weight: bold" >${label}</span><div>${description}</div>`;
            } else {
                if (isOverflowing) {
                    tooltip = label;
                }
            }
        }
        return tooltip;
    }

    public onNodeExpand(event: { originalEvent: Event, node: TreeNode }) {
        let node:TreeNode = event.node;
        if (node) {
            this.expandNode(node);
        }
    }

    public expandNode(node: TreeNode, callbackFn?: (node: TreeNode) => void) {
        node.expanded = true;
        if (node.children == null) {
            const itemQuery: BomItemNodeQuery = this.createBomItemNodeQuery(node.key, this._bomFilter);
            this.setNodeLoadingIcon(node, true);
            // "fa fa-spinner fa-spin"
            this.pipelineService.readItemNode(itemQuery).subscribe(
                {
                    next:(pipelineNode: BomItemNode) => {
                        node.children = (pipelineNode ? pipelineNode.toPrimeNgTreeNode().children : []);
                        this.setNodeLoadingIcon(node, false);
                        if (callbackFn) {
                            callbackFn(node);
                        }
                        this.cdr.markForCheck();
                    },
                    error:error => {
                        this.setNodeLoadingIcon(node, false);
                        this.cdr.markForCheck();
                    }
                }
            );
            this.cdr.markForCheck();
        } else {
            if (callbackFn) {
                callbackFn(node);
            }
        }
    }

    private handleRefreshTreeRequest(request:TreeRefreshRequest) {
        const itemQuery: BomItemNodeQuery = this.createBomItemNodeQuery("root", this._bomFilter);
        const nodeIdToSelect:string = request.nodeIdToSelect;
        if (nodeIdToSelect) {
            this.pipelineService.readItemNode({itemId: nodeIdToSelect}).subscribe(bomItemNode => {
                let idToSelect = nodeIdToSelect;
                if (bomItemNode) {
                    if (bomItemNode.isStage()) {
                        itemQuery.expandedPathItemIds.push(String(bomItemNode.bomItem.parentId));
                    } else {
                        itemQuery.expandedPathItemIds.push(String(nodeIdToSelect));
                    }
                } else {
                    idToSelect = null;
                }

                this.refreshTree$(itemQuery).subscribe(next=>{
                    this.selectNodeById(idToSelect, request.fromActivatedRoute, request.forceRefresh);
                });
            });
        } else {
            this.refreshTree$(itemQuery).subscribe(next=>{
                this.selectNodeById(nodeIdToSelect, request.fromActivatedRoute, request.forceRefresh);
            });
        }
    }

    /**
     * Handler for the 'Select All Pipelines' or 'Unselect All' actions.
     *
     * @param isSelect true if all pipelines are to be selected. False to clear all selection.
     */
    private handleAllPipelineSelection(isSelect:boolean) {
        if (!this.isEmpty()) {
            if (isSelect) {
                const selection = [];
                for (const treeNode of this.treeNodes) {
                    selection.push(treeNode);
                }
                this.selection = selection;
            } else {
                this.selection = [];
            }
            this.onSelectionChange(this.selection);
            this.cdr.markForCheck();
        }
    }

    // noinspection JSMethodCanBeStatic
    private setNodeLoadingIcon(node:TreeNode, isLoading:boolean) {
        let iconCls:string;
        if (isLoading) {
            iconCls = "fa fa-sfa fa-spinner fa-spin";
        } else {
            iconCls = (node.data as BomItemNode).iconCls;
        }
        node.collapsedIcon = node.expandedIcon = iconCls;
    }

    private refreshTree$(itemQuery?:BomItemNodeQuery):Observable<any> {
        if (itemQuery == null) {
            itemQuery = this.createBomItemNodeQuery("root", this._bomFilter);
        }
        return new Observable(subscriber=>{
            this.devEnvService.currentDevEnv$.subscribe(devEnv=>{
                this.loading = true;
                this.pipelineService.readItemNode(itemQuery)
                    .subscribe(
                        {
                            next:(rootBomNode: BomItemNode) => {
                                let rootNode: TreeNode = rootBomNode.toPrimeNgTreeNode();
                                this.treeNodes = rootNode.children || [];
                                this.loading = false;
                                this.expandTreeNodes(this.treeNodes, itemQuery.expandedPathItemIds);
                                // removed saved states since it's been processed
                                this.appService.setObject(PipelineTreeComponent.BOM_TREE_STATES_KEY, undefined);
                                this.cdr.markForCheck();
                                subscriber.next();
                                subscriber.complete();
                            },
                            error:error => {
                                this.loading = false;
                                this.cdr.markForCheck();
                                subscriber.error(error);
                            }
                        }
                    );
                this.cdr.markForCheck();
            });
        });

    }

    public onNodeContextMenuSelect(event:{originalEvent:any, node:TreeNode}) {
        const selectedNode = event.node;
        const selectedNodes:TreeNode[] = this.selection || [];
        if (selectedNodes.length) {
            if (selectedNodes.length > 1) {
                this.bomActionsHelper.getMultiNodeSelectionActions(selectedNodes, true)
                    .subscribe(items => {
                        this.contextMenuItems = items;
                        this.cdr.markForCheck();
                    });
            } else {
                this.bomActionsHelper.getContextMenuActions(selectedNode.data).subscribe(items => {
                    this.contextMenuItems = items;
                    this.cdr.markForCheck();
                });
            }
        } else {
            this.contextMenuItems = [];
            this.cdr.markForCheck();
        }
    }

    public onSelectionChange(selection: any, fromActivatedRoute?: boolean, forceRefresh?: boolean) {
        this.selectionChange.emit({selection, fromActivatedRoute, forceRefresh});
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        // saved pipeline tree states
        const treeStates:PipelineTreeStates = {
            expandedNodes:this.getExpandedNodes()
        };
        this.appService.setObject(PipelineTreeComponent.BOM_TREE_STATES_KEY, treeStates);
        // console.log("saved states:", treeStates);
    }

    /**
     * Expands nodes that are in the nodeIdsToExpand array.
     *
     * @param nodes nodes to expand recursively
     * @param nodeIdsToExpand Ids of nodes to expand
     */
    private expandTreeNodes(nodes:TreeNode[], nodeIdsToExpand:string[]) {
        nodes = nodes || [];
        nodeIdsToExpand = nodeIdsToExpand || [];
        if (nodeIdsToExpand.length) {
            for (const nextNode of nodes) {
                if (nodeIdsToExpand.indexOf(nextNode.data.id + "") != -1) {
                    nextNode.expanded = true;
                    this.expandNode(nextNode, (node) => {
                        const childNodes = node.children;
                        if (childNodes != null && childNodes.length) {
                            this.expandTreeNodes(childNodes, nodeIdsToExpand);
                        }
                    });
                }
            }
        }
    }

    /**
     * Creates the query object to retrieve bom items.
     *
     * @param itemId item ID to query
     * @param itemFilter filter to apply
     * @return the query object to query for item nodes
     */
    private createBomItemNodeQuery(itemId:string, itemFilter:BomItemFilter):BomItemNodeQuery {
        let selectedStageItems:StageItem[] = [];
        let expandedNodes = this.getExpandedNodesForQuery();
        let expandedPathItemIds:string[] = [];
        for (const nextExpandedNode of expandedNodes) {
            let bomItemNode:BomItemNode = nextExpandedNode.data as BomItemNode;
            if (bomItemNode.isStage()) {
                selectedStageItems.push({
                    stageId:bomItemNode.stageId,
                    stageType:bomItemNode.stageType
                });
            } else if (bomItemNode.isPipeline() || bomItemNode.isPath()) {
                expandedPathItemIds.push(bomItemNode.id + "");
            }
        }
        const itemQuery:BomItemNodeQuery = {
            itemId:itemId,
            selectedStageItems:selectedStageItems,
            expandedPathItemIds:expandedPathItemIds
        };

        if(itemFilter != null) {
            Object.assign(itemQuery, {
                itemName:itemFilter.searchText,
                showDirtyOnly:itemFilter.isShowDirtyOnly(),
                showPathOnly:itemFilter.isShowPathOnly(),
                stageTypes:itemFilter.getStageTypes(),
            });
        }
        return itemQuery;
    }

    private getExpandedNodesForQuery():TreeNode[] {
        let expandedNodes = this.getExpandedNodes();
        let finalExpandedNodes = [...expandedNodes]
        let savedExpandedNodes:TreeNode[] = [];
        const savedTreeStates:PipelineTreeStates = this.appService.getObject(PipelineTreeComponent.BOM_TREE_STATES_KEY);
        if (savedTreeStates) {
            savedExpandedNodes = savedTreeStates.expandedNodes || [];
        }
        for (const nextSavedExpandedNode of savedExpandedNodes) {
            const found = expandedNodes.find((expNode)=>{
                return expNode.key === nextSavedExpandedNode.key;
            });
            if (!found) {
                finalExpandedNodes.push(nextSavedExpandedNode);
            }
        }
        // console.log("finalExpandedNodes = ", finalExpandedNodes);
        return finalExpandedNodes;
    }

    private selectNodeById(nodeId: string, fromActivatedRoute: boolean, forceRefresh: boolean) {
        if (this.tree) {
            // NOTE:noteId must be string for matching to work properly
            const matchedNode: TreeNode = this.tree.getNodeWithKey(String(nodeId), this.treeNodes || []);
            if (matchedNode) {
                this.expandParentNodes(matchedNode);
                // for a selection change event to refresh the tree and preview
                this.selection = [matchedNode];
                this.onSelectionChange(this.selection, fromActivatedRoute, forceRefresh);
                this.cdr.markForCheck();
            } else {
                this.selection = [];
                this.onSelectionChange(this.selection, fromActivatedRoute, forceRefresh);
                this.cdr.markForCheck();
            }
        }
    }

    private expandParentNodes(treeNode:TreeNode) {
        let parent = treeNode.parent;
        while (parent) {
            this.expandNode(parent);
            parent = parent.parent;
        }
        this.cdr.markForCheck();
    }

    /**
     * Gets the nodes that are currently expanded in the tree.
     */
    private getExpandedNodes():TreeNode[] {
        let expandedNodes:TreeNode[] = [];
        function collectExpandedNodes(pNodes:TreeNode[]) {
            let treeNodes = pNodes || [];
            for (const nextNode of treeNodes) {
                if (nextNode.expanded) {
                    expandedNodes.push(nextNode);
                }
                if (nextNode.children != null) {
                    collectExpandedNodes(nextNode.children);
                }
            }
        }
        collectExpandedNodes(this.treeNodes);

        return expandedNodes;
    }
}